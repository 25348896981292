import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout'
import { breakpoints } from '../others/breakpoints';
import { Subscription } from 'rxjs';
import { MatDrawerMode } from '@angular/material/sidenav';
import { Router } from '@angular/router';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit, OnDestroy {

  public mode: MatDrawerMode = "over";
  public modeChanged = false;
  public menuProfile = false;
  private subscription?: Subscription;
  constructor(private _observer: BreakpointObserver, private _router:Router) { }

  ngOnInit(): void {
    this.subscription = this._observer.observe(`(min-width: ${breakpoints.lg})`).subscribe(x => {
      if (x.matches) {
        this.mode = "side";
        this.modeChanged = false;
      } else {
        this.mode = "over"
        this.modeChanged = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }

  @HostListener('click', ['$event.target'])
  onClick(btn: HTMLElement) {
    if (!btn.closest('[role="menu"], [aria-controls]')) {
      this.menuProfile = false;
    }
  }

  logout() {
    sessionStorage.clear();
    localStorage.removeItem("refresh")
    this._router.navigate(["/"]);
  }
}
