import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { finalize, Observable, of, tap } from 'rxjs';
import { Router } from '@angular/router';
import {
  DONT_REPORT_ERROR_REQUEST,
  IAMREFRESH_REQUEST,
  NON_AUTH_REQUEST,
  NO_401REDIRECT_REQUEST,
  SILENT_REQUEST
} from './constants';
import { RefreshService } from './refresh.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  private ajaxInProgress = 0;
  private refreshInProgress = false;
  constructor(private _router: Router,
    private _refresh: RefreshService,
    private _snacks: MatSnackBar) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.refreshInProgress && !request.context.get(IAMREFRESH_REQUEST)) { return of(); }
    let ok: number;
    let ajaxNoted = false;
    let noRedirectOn401 = false;
    let errorOccured = false;
    let errorData: any = null;

    // extend server response observable with logging
    if (!request.context.get(SILENT_REQUEST)) {
      document.body.classList.add("the-ajax-progress");
      ajaxNoted = true;
      this.ajaxInProgress++
    }
    if (request.context.get(NO_401REDIRECT_REQUEST)) {
      noRedirectOn401 = true;
    }
    return next.handle(request)
      .pipe(
        tap({
          // Succeeds when there is a response; ignore other events
          next: (event) => (ok = event instanceof HttpResponse ? event.status : -1),
          // Operation failed; error is an HttpErrorResponse
          error: (error) => {
            ok = (error as HttpErrorResponse).status || -1;
            errorOccured = true;
            errorData = (error as HttpErrorResponse).error;
          }
        }),
        // Log when response observable either completes or errors
        finalize(() => {
          if (!errorOccured) {
            this._refresh.removeHideMe();
          }
          if (ok == 401 && !noRedirectOn401) {
            let url = this._router.url;
            this._refresh.refresh(() => {
              this._router.navigateByUrl("/", { skipLocationChange: true })
                .then(() => this._router.navigateByUrl(url));
            })
          } else if (errorOccured) {
            if (!request.context.get(DONT_REPORT_ERROR_REQUEST)) {
              this._snacks.open(
                "Action didn't succeed. " +
                (errorData.dbError && errorData.col && `${errorData.col} already exists.` || ''),
                "Dismiss",
                { duration: 5000 });
            }
          } else if (!noRedirectOn401 && !request.context.get(NON_AUTH_REQUEST)) {
            this._refresh.restartIfStopped();
          }
          if (ajaxNoted) {
            this.ajaxInProgress--;
            ajaxNoted = false;
            if (this.ajaxInProgress <= 0) {
              document.body.classList.remove("the-ajax-progress");
            }
          }
        })
      );
  }
}
