import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpClient,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, of } from 'rxjs';
import { NON_AUTH_REQUEST } from './constants';
import { RefreshService } from './refresh.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private http: HttpClient) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let authRequest = request;
    if (!request.context.get(NON_AUTH_REQUEST)) {
      // Get the auth token from the service.
      const authToken = 'Bearer ' + sessionStorage.getItem("access") || 'none';

      // Clone the request and replace the original headers with
      // cloned headers, updated with the authorization.
      authRequest = request.clone({
        headers: request.headers.set('Authorization', authToken)
      });
    }
    // send cloned request with header to the next handler.
    return next.handle(authRequest);
  }
}
