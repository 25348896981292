import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs';
import { environment } from 'src/environments/environment';
import { doStartUpDuties } from '../general/constants';
import { ConfirmDialogService } from '../material/confirm-dialog.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public samePassword: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (control.get("password")?.value != control.get("password2")?.value) {
      return { notSame: true };
    }
    return null;
  };
  public forgotPwd = new FormGroup({
    "email": new FormControl('', [Validators.required, Validators.email]),
    "password": new FormControl('', [Validators.required]),
    "password2": new FormControl('', [Validators.required])
  }, [this.samePassword]);
  public token = new FormControl('', [Validators.required]);

  public step = false;
  public success = false;
  public loading = false;
  constructor(private _router: Router, private _http:HttpClient, private _confirm: ConfirmDialogService) { }
  
  ngOnInit(): void {
    doStartUpDuties();
    this.step = false;
    this.success = false;
  }
  step1() {
    this.forgotPwd.markAllAsTouched();
    if (this.forgotPwd.invalid) {
      return;
    }
    this.loading = true;
    this._http.post<any>(`${environment.apiBase}auth/change-password-step1`,
      { email: this.forgotPwd.get("email")?.value }
    ).pipe(finalize(
      () => { this.loading = false; })
    ).subscribe(x => {
      if (x && x.success) {
        this.step = true;
        this.success = false;
      } else {
        this._confirm.alert("Email not correct", "failure");
      }
    });    
  }
  backToStep1(e: Event) {
    this.step = false;
    this.success = false;
    e.preventDefault();
  }
  step2() {
    this.token.markAsTouched();
    if (this.token.invalid) {
      return;
    }
    this.loading = true;
    this._http.post<any>(`${environment.apiBase}auth/change-password-step2`,
      {
        email: this.forgotPwd.get("email")?.value,
        password: this.forgotPwd.get("password")?.value,
        token: this.token.value,
      }
    ).pipe(finalize(
      () => { this.loading = false; })
    ).subscribe(x => {
      if (x && x.success) {
        this.step = true;
        this.success = true;
      } else {
        this._confirm.alert("Invalid token", "failure");
      }
    }); 
  }
  step3() {
    this._router.navigate(["/"]);
  }
  hasError(name: string | null, errors: string[]) {
    let control:AbstractControl | null = this.forgotPwd;
    if (name) {
      control = this.forgotPwd.get(name)
    }
    return control?.touched && errors.some(x => control?.hasError(x));
  }
}
