<mat-sidenav-container class="the-custom" [hasBackdrop]="false">
  <mat-sidenav #sidenav [mode]="mode" [opened]="!modeChanged" [autoFocus]="false"
    class="custom inverted primary sidenav-width"
    [fixedInViewport]="true">
    <div class="b-disp-flex b-align-items-start b-width-100">
      <div class="the-title b-disp-flex b-align-items-center b-flex-grow-1 b-justify-content-center">
        <img alt="Logo" src="assets/svgs/smcLogoInverted.png" height="50px">
      </div>
      <div class="b-disp-flex b-align-items-center b-flex-shrink-1 b-justify-content-end" *ngIf="mode != 'side'">
        <a mat-icon-button (click)="sidenav.close()">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path
              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </a>
      </div>
    </div>
    <app-menu></app-menu>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="the-band b-disp-flex b-justify-content-between b-align-items-center">
      <div class="b-disp-flex b-justify-content-start b-align-items-center">
        <mat-icon class="the-icon" (click)="sidenav.toggle()">menu</mat-icon>
        <img alt="Logo" src="assets/svgs/smcLogo.png" height="50px">
      </div>
      <button mat-mini-fab color="primary" class="the-icon" aria-haspopup="menu" 
        aria-controls="profile-menu" (click)="menuProfile = !menuProfile">
        <mat-icon>person</mat-icon>
      </button>
      <div id="profile-menu" role="menu" [class.active]="menuProfile">
        <button type="button" role="menuitem" mat-button [routerLink]="[ '/setpassword' ]" class="use-color-icon">
          <mat-icon>lock_open</mat-icon>
          <span>Change Password</span>
        </button>
        <button type="button" role="menuitem" mat-button (click)="logout()" class="use-color-icon">
          <mat-icon>logout</mat-icon>
          <span>Logout</span>
        </button>
      </div>
    </div>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>