<section>
  <div class="b-disp-flex b-flex-column b-justify-content-center b-align-items-center b-width-100">
    <article class="the-logo">
      <img src="assets/svgs/smcLogo.png" alt="St Mary's Church" height="100" />
    </article>
    <article>
      <div>
        <label for="username">Phone Number</label>
      </div>
      <div>
        <input type="text" class="b-padding-2 b-width-100 b-border-1 b-radius-1" id="username" tabindex="1"
          [formControl]="username" />
      </div>
      <div *ngIf="username.touched && username.invalid" class="custom color warn the-small-text">
        Phone number Required
      </div>
    </article>
    <article>
      <div class="b-width-100 b-disp-flex b-justify-content-between">
        <label for="password">Password</label>
        <a [routerLink]="['/forgot-password']" class="custom color accent plain-link" tabindex="4">Forgot Password?</a>
      </div>
      <div>
        <input type="password" id="password" tabindex="2" class="b-padding-2 b-width-100 b-border-1 b-radius-1"
          (keyup.enter)="enter()" [formControl]="password" />
      </div>
      <div *ngIf="password.touched && password.invalid" class="custom color warn the-small-text">
        Password Required
      </div>
    </article>
    <article>
      <button mat-flat-button tabindex="3" color="accent" type="button" class="b-width-100" (click)="enter()">
        Sign in
      </button>
    </article>
  </div>
  <div class="copyright b-disp-flex b-justify-content-between b-width-100">
    <span class="c-copy">© 2023 St. Mary's Church Bommanahalli</span>
    <span>&nbsp;</span>
    <span class="c-powered">Powered by Amazecodes</span>
  </div>
</section>