import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ConfirmDialogComponent } from './confirm-dialog.component';
@Injectable()
export class ConfirmDialogService {
  private dialogRef?: MatDialogRef<ConfirmDialogComponent>
  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  public open(options: {
    cancelText?: string;
    confirmText?: string;
    message: string;
    title?: string;
  }) {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: options.title,
        message: options.message,
        cancelText: options.cancelText,
        confirmText: options.confirmText,
      },
    });
    return this.dialogRef;
  }
  public confirmed(): Observable<any> {
    return this.dialogRef!.afterClosed().pipe(
      take(1),
      map((res) => {
        return res;
      })
    );
  }
  public alert(message: string, color: "success" | "failure") {
    let classes = {
      "success": "success",
      "failure": "warn"
    }
    this.snackBar.open(message, "Dismiss", {
      panelClass: ["custom", classes[color]],
      duration: 5000,
    })
  }
}
