import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, lastValueFrom, of } from 'rxjs';
import {
  anyScopePresent,
  doStartUpDuties,
  IAMREFRESH_REQUEST,
  newContext,
  NON_AUTH_REQUEST,
  NO_401REDIRECT_REQUEST,
  SILENT_REQUEST,
  URL_REFRESH
} from './constants';

@Injectable({
  providedIn: 'root',
})
export class RefreshService {
  timeHandle: NodeJS.Timeout | null = null;
  constructor(private _http: HttpClient, private _router: Router) { }
  refresh(fn: (() => void) | null = null) {
    if (!localStorage.getItem('refresh')) {
      this.signOut();
      this._router.navigate(["/"]);
    }
    this._http
      .post<any>(
        URL_REFRESH,
        {
          refreshToken: localStorage.getItem('refresh') || 'none',
        },
        {
          context: newContext()
            .set(SILENT_REQUEST, true)
            .set(NON_AUTH_REQUEST, true)
            .set(NO_401REDIRECT_REQUEST, true)
            .set(IAMREFRESH_REQUEST, true)
        }
      )
      .pipe(
        catchError((err, _cg) => {
          if (err) {
            this.signOut();
            this._router.navigate(["/"]);
          }
          return of();
        })
      )
      .subscribe((x) => {
        this.signIn(x);
        this.removeHideMe();
        if (fn) {
          fn();
        }
      });
  }
  signIn(x: any) {
    this.clearTimeHandle()
    sessionStorage.setItem('access', x.accessToken);
    localStorage.setItem('refresh', x.refreshToken);
    this.continueRefresh(x);
  }
  signOut() {
    sessionStorage.clear();
    this.clearTimeHandle()
  }
  clearTimeHandle() {
    if (this.timeHandle) {
      clearTimeout(this.timeHandle);
      this.timeHandle = null;
    }
  }
  continueRefresh(x:any) {
    if (this.timeHandle == null) {
      let time = Math.floor(x.expires && (x.expires / 3) || 5) * 1000;
      this.timeHandle = setTimeout(() => {
        this.refresh();
      }, time);
    }
  }
  restartIfStopped() {
    if (this.timeHandle == null && anyScopePresent(["user"])) {
      this.refresh();
    }
  }
  removeHideMe() {
    doStartUpDuties();
  }
}
