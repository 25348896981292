<section [formGroup]="forgotPwd" *ngIf="!step">
    <article class="the-logo">
        <img src="assets/svgs/smcLogo.png" alt="St Mary's Church" height="100" />
    </article>
    <article class="the-small-head b-disp-flex b-justify-content-between b-flex-gap-4 b-align-items-center">
        <a [routerLink]="['/']" class="custom color accent plain-link">
            <mat-icon>arrow_back</mat-icon>
        </a>
        <h3 class="b-margin-0 b-padding-0">Forgot Password Step 1</h3>
    </article>
    <article>
        <div>
            <label for="email">Email</label>
        </div>
        <div>
            <input type="text" class="b-padding-2 b-width-100 b-border-1 b-radius-1" id="email" formControlName="email"
                autocomplete="off" form="presentation" />
        </div>
        <div class="custom color warn" *ngIf="hasError('email', ['required', 'email'])">
            <span>A valid email is required</span>
        </div>
    </article>
    <article>
        <div>
            <label for="password">New Password</label>
        </div>
        <div>
            <input type="password" id="password" class="b-padding-2 b-width-100 b-border-1 b-radius-1"
                formControlName="password" autocomplete="new-password" form="presentation" />
        </div>
        <div class="custom color warn" *ngIf="hasError('password', ['required'])">
            <span>Password required</span>
        </div>
    </article>
    <article>
        <div>
            <label for="password2">Reenter New Password</label>
        </div>
        <div>
            <input type="password" id="password2" class="b-padding-2 b-width-100 b-border-1 b-radius-1"
                formControlName="password2" autocomplete="new-password" form="presentation" />
        </div>
        <div class="custom color warn" *ngIf="hasError('password2', ['required']) || hasError(null, ['notSame'])">
            <span>Reenter same password</span>
        </div>
    </article>
    <article>
        <button mat-flat-button color="accent" type="button" class="b-width-100" 
            (click)="step1()" [disabled]="loading">
            <span class="b-width-100 b-disp-flex b-justify-content-center b-align-items-center">
                <mat-spinner *ngIf="loading" [diameter]="16" color="primary"></mat-spinner>
                <span class="b-disp-block">
                    Send Verification Code To Email
                </span>
            </span>
        </button>
        <form id="presentation" autocomplete="off">
        </form>
    </article>
</section>
<section *ngIf="step && !success">
    <article class="the-logo">
        <img src="assets/svgs/smcLogo.png" alt="St Mary's Church" height="100" />
    </article>
    <article class="the-small-head b-disp-flex b-justify-content-between b-align-items-center">
        <a (click)="backToStep1($event)" href="#" class="custom color accent plain-link">
            <mat-icon>arrow_back</mat-icon>
        </a>
        <h3 class="b-margin-0 b-padding-0">Forgot Password Step 2</h3>
    </article>
    <article>
        <div>
            <label for="token">Verification Code (Token)</label>
        </div>
        <div>
            <input type="text" class="b-padding-2 b-width-100 b-border-1 b-radius-1" id="token" [formControl]="token" />
        </div>
        <div class="custom color warn" *ngIf="token.touched && token.hasError('required')">
            <span>Enter token sent to the given email</span>
        </div>
    </article>
    <article>
        <button mat-flat-button color="accent" type="button" class="b-width-100"
            (click)="step2()" [disabled]="loading">
            <span class="b-width-100 b-disp-flex b-justify-content-center b-align-items-center">
                <mat-spinner *ngIf="loading" [diameter]="16" color="primary"></mat-spinner>
                <span class="b-disp-block">
                    Reset Password
                </span>
            </span>
        </button>
    </article>
</section>
<section *ngIf="step && success">
    <article class="the-logo">
        <img src="assets/svgs/smcLogo.png" alt="St Mary's Church" height="100" />
    </article>
    <article class="the-small-head b-disp-flex b-justify-content-between b-align-items-center">
        <a [routerLink]="['/']" class="custom color accent plain-link">
            <mat-icon>arrow_back</mat-icon>
        </a>
        <h3 class="b-margin-0 b-padding-0">Password Change Successful</h3>
    </article>
    <article>
        <div>
            Your password was successfully changed to new one you specified.
            Please click the below button to login with new password
        </div>
    </article>
    <article>
        <button mat-flat-button color="accent" type="button" class="b-width-100" (click)="step3()">
            Login with New Password
        </button>
    </article>
</section>