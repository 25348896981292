import { HttpContext, HttpContextToken } from "@angular/common/http";
import { MatDialogConfig } from "@angular/material/dialog";
import { environment } from "src/environments/environment";

export const newContext = () => new HttpContext();
export const NON_AUTH_REQUEST = new HttpContextToken(() => false);
export const SILENT_REQUEST = new HttpContextToken(() => false);
export const NO_401REDIRECT_REQUEST = new HttpContextToken(() => false);
export const DONT_REPORT_ERROR_REQUEST = new HttpContextToken(() => false);
export const IAMREFRESH_REQUEST = new HttpContextToken(() => false);
// if NO_AUTH_REQUEST is different value the errors may happen
export const URL_REFRESH = `${environment.apiBase}auth/refresh-token`;

export function getTokenJSON(): any | undefined {
    let token = sessionStorage.getItem("access");
    if (token && token.indexOf(".") > -1) {
        let data = window.atob(token.split(".")[1]);
        
        try {
            return JSON.parse(
                data
            );
        } catch (e) {
            console.log("Token parsing error", e);
        }
    }
    return undefined;
}
export function getTokenScope(): Array<string> | undefined {
    return getTokenJSON()?.scope as Array<string> | undefined;
}
export function anyInScope(scope: Array<string>, allowInit = true): boolean {
    let token = getTokenScope();
    if (!token && allowInit) { return true; }
    let found = (token || []).some((x: string) => scope.indexOf(x) > -1);
    if (!found) {
        window.location.href = "/404";
    }
    return found || false;
}

export function anyScopePresent(scope: Array<string>): boolean {
    let token = getTokenScope();
    if (!token) { return false; }
    let found = (token || []).some((x: string) => scope.indexOf(x) > -1);
    return found || false;
}

export function defaultEditPopupConfig<T>(data: T): MatDialogConfig<T> {
    return {
        height: "100vh",
        width: "500px",
        maxWidth: "100vw",
        position: {
            right: '0',
            top: '0'
        },
        data: data,
    }
}

export function doStartUpDuties() {
    document.body.classList.remove("use-hide-me");
}