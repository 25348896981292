<!-- <ng-template [ngIf]="true">
    <a [routerLink]="[ '/users']" data-match="/dashboard" class="the-menu custom primary hover plain-link" #item
        [class.inverted]="!isThe(item)" [class.contrast]="isThe(item)">
        <mat-icon>dashboard</mat-icon>
        Dashboard
    </a>
</ng-template> -->
<ng-template [ngIf]="true">
    <a [routerLink]="[ '/family/list']" data-match="/family" class="the-menu custom primary hover plain-link" #item
        [class.inverted]="!isThe(item)" [class.contrast]="isThe(item)">
        <mat-icon>house</mat-icon>
        Family List
    </a>
</ng-template>
<ng-template [ngIf]="true">
    <a [routerLink]="[ '/users']" data-match="/users" class="the-menu custom primary hover plain-link" #item
        [class.inverted]="!isThe(item)" [class.contrast]="isThe(item)">
        <mat-icon>manage_accounts</mat-icon>
        User Management
    </a>
</ng-template>

