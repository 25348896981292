import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { anyInScope } from './general/constants';
import { GeneralComponent } from './layouts/general/general.component';
import { LoginComponent } from './login/login.component';

const title = "St Mary's Church Bommanahalli";

const routes: Routes = [
  {
    title: title,
    component: GeneralComponent,
    path: 'users',
    canActivate: [() => anyInScope(["user"])],
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
  },
  {
    title: title,
    component: GeneralComponent,
    path: "family",
    canActivate: [() => anyInScope(["user"])],
    loadChildren: () => import('./family/family.module').then(m => m.FamilyModule)
  },
  {
    title: title,
    component: ForgotPasswordComponent,
    path: 'forgot-password',
    pathMatch: "full"
  },
  {
    canActivate: [() => anyInScope(["user"], false)],
    title: title,
    path: 'setpassword',
    pathMatch: "full",
    loadComponent: () => import('./layouts/change-password.component').then(mod => mod.ChangePasswordComponent)
  },
  {
    title: title,
    component: LoginComponent,
    path: '',
    pathMatch: "full"
  },
  {
    title: title,
    path: "404",
    pathMatch: "full",
    loadComponent: () => import('./layouts/not-found.component').then(mod => mod.NotFoundComponent) 
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
