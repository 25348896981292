import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { catchError, EMPTY } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DONT_REPORT_ERROR_REQUEST, newContext, NON_AUTH_REQUEST, NO_401REDIRECT_REQUEST } from '../general/constants';
import { RefreshService } from '../general/refresh.service';
import { ConfirmDialogService } from '../material/confirm-dialog.service';
const URLS = {
  login: `${environment.apiBase}auth/login`,
};
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(private _router: Router,
    private _http: HttpClient,
    private _refresh: RefreshService,
    private _confirm: ConfirmDialogService) { }

  public username = new FormControl('', Validators.required);
  public password = new FormControl('', Validators.required);

  ngOnInit(): void {
    this._refresh.removeHideMe();
  }

  submit() {
    this._http.post<any>(
      URLS.login,
      { userName: this.username.value, password: this.password.value },
      {
        context: newContext()
          .set(NON_AUTH_REQUEST, true)
          .set(NO_401REDIRECT_REQUEST, true)
          .set(DONT_REPORT_ERROR_REQUEST, true)
      }
    ).pipe(
      catchError(() => {
        this._confirm.alert("Phone number or password not correct", "failure");
        return EMPTY;
      })
    ).subscribe(x => {
      this._refresh.signIn(x);
      this._router.navigate(["/family/list"]);
    });
  }
  enter() {
    this.username.markAsTouched();
    this.password.markAllAsTouched();
    if (this.username.value && this.password.value) {
      this.submit();
    }
  }
}
