import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { getTokenScope } from 'src/app/general/constants';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {

  public selected: string | null = null;
  public subscription?: Subscription;
  public isAdmin = getTokenScope()?.some(x => ['admin', 'grouphead'].includes(x)) || false;
  constructor(private _router: Router) { 
    this.subscription = this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.selected = _router.url;
      }
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }
  
  isThe(el: HTMLElement): boolean {
    let current = this.selected?.toLowerCase();
    let match = el.getAttribute("data-match");
    if (match && current?.startsWith(match?.toLowerCase())) {
      return true;
    }
    return false;
  }
}
